import React, { useState } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import App from '../images/sprite/btn-appdl-android.png'
import betfair from '../images/sprite/logo-betfair.png'


export default function Support(props) {
	const [Socialicon, setSocialicon] = useState(0);



	return (
		<React.Fragment>

			<div id="supportWrap" className="support-wrap">
				<div className="support-service">
					<a id="support_email" onClick={() => { setSocialicon(0) }} className={`support-mail ui-link ${(Socialicon === 0) ? "open" : "null"}`}>
						<img src={Transparent} title="Email" /></a>
					<a id="support_whatsapp" onClick={() => { setSocialicon(1) }} className={`support-whatsapp ui-link ${(Socialicon === 1) ? "open" : "null"}`}>
						<img src={Transparent} title="WhatsApp" /></a>
					<a
						id="support_telegram" onClick={() => { setSocialicon(2) }} className={`support-fb ui-link ${(Socialicon === 2) ? "open" : "null"}`}>
						<img src={Transparent} title="facebook" /></a>
					<a id="support_skype" onClick={() => { setSocialicon(3) }} className={`support-skype ui-link ${(Socialicon === 3) ? "open" : "null"}`} >
						<img src={Transparent} title="Skype" /></a>
					<a
						id="support_instagram" onClick={() => { setSocialicon(4) }} className={`support-ig ui-link ${(Socialicon === 4) ? "open" : "null"}`} >
						<img src={Transparent} title="Instagram" /></a>
				</div>
				<div className="support-info">
					<div id="supportDetail_email" className={`support-detail ${(Socialicon === 0) ? "open" : "null"}`}>
						{Socialicon === 0 && <a className="ui-link" href="https://t.me/aagun_bet"><span>aagun_bet</span></a>
						}</div>

					<div id="supportDetail_whatsapp" className={`support-detail ${(Socialicon === 1) ? "open" : "null"}`} >
						{Socialicon === 1 && <a class="ui-link" href="https://wa.me/+971524225063"><span>+97-1524225063</span></a>}
						{/* {Socialicon === 1 && <a class="ui-link" href="https://wa.me/000"><span>000</span></a>}
						{Socialicon === 1 && <a class="ui-link" href="https://wa.me/000"><span>000</span></a>} */}
					</div>
					<div id="supportDetail_telegram" className={`support-detail ${(Socialicon === 2) ? "open" : "null"}`} >
						{Socialicon === 2 && <a class="ui-link" href="#"><span>aagunfacebook</span></a>}
					</div>
					<div id="supportDetail_skype" className={`support-detail ${(Socialicon === 3) ? "open" : "null"}`} >
						{Socialicon === 3 && <a class="ui-link"><span>aagunofficial</span></a>}
					</div>
					<div id="supportDetail_instagram" className={`support-detail ${(Socialicon === 4) ? "open" : "null"}`} >
						{Socialicon === 4 && <a class="ui-link"><span>officialaagun</span></a>}
					</div>
				</div>
			</div>

			<ul _ngcontent-njs-c67="" class="mob-inform-link" style={{ display: ' block' }}>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(4, true) }} >KYC</a></li>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(6, true) }}>About Us</a></li>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(1, true) }}>Privacy Policy</a></li>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(5, true) }}>Responsible Gaming</a></li>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(2, true) }}>Terms and Conditions</a></li>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(8, true) }}>Underage Gaming Policy</a></li>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(7, true) }}>Self-Exclusion Policy</a></li>
				<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" onClick={() => { props.handlepolicy(3, true) }}>Rules and Regulations</a></li>

			</ul>

			<div _ngcontent-njs-c67="" className="extra-wrap">
				<div _ngcontent-njs-c67="" id="powerWrap" className="power-content-b" style={{ display: 'block' }}>
					<span _ngcontent-njs-c67="" >Powered by</span><img src={betfair} />
				</div>
				<div _ngcontent-njs-c67="" className="appdl-link-android" style={{ display: 'block' }}>
					<a _ngcontent-njs-c67="" href="#"><img src={App} alt /></a>
					<p _ngcontent-njs-c67="" >v1 - 2022-08-06 - 4.0MB</p>
				</div>
			</div>

		</React.Fragment>
	)
}











{/* <a id="support_email"  
		onClick ={()=>{setSocialicon(0)}} 
		className={`support-mail ui-link ${(Socialicon===0 )? "openn": "null"}`}
		><img src={Transparent} title="Email"/></a> */}

{/* <a id="support_skype"   
		onClick ={()=>{setSocialicon(3)}} 
		className={`support-skype  ${(Socialicon===3 )? "openn": "null"}`}
		><img src={Transparent} title="Skype"/></a>
		
		
		<a id="support_instagram"  
		onClick ={()=>{setSocialicon(4)}} 
		className={`support-ig  ${(Socialicon===4 )? "openn": 
		"null"}`}
		><img src={Transparent} title="Instagram"/></a> */}
{/* { Socialicon ===0 &&
		<div id="supportDetail_email" 
		className={`support-detail ${(Socialicon===0 )? "open": "null"}`}
		><a   class="ui-link"><span style={{color: '#666' , textDecoration:'underline'}}>info@aagun.games</span></a></div>
	} */}


{/* 		
		<div id="supportDetail_skype" className={`support-detail ${(Socialicon===3 )? "open": "null"}`}>
		{ Socialicon ===3 &&
			<a   class="ui-link"><span style={{color: '#666' , textDecoration:'underline'}}>aagunofficial</span></a>
		}
			</div>
		
		
		<div id="supportDetail_instagram" className={`support-detail ${(Socialicon===4 )? "open": "null"}`}>
		{ Socialicon ===4 &&
			
			<a   class="ui-link"><span style={{color: '#666' , textDecoration:'underline'}}>officialaagun</span></a>
			}
			</div> */}
