import React from 'react'
import Cricketjpg from '../images/kv/KV01.jpg'
import Matches from './CricketMatches'


export default function Cricket(props) {
  return (
    <div _ngcontent-lst-c69="" >

      <div _ngcontent-lst-c69="" >
        <div _ngcontent-lst-c69="" class="images-grid">
          <img _ngcontent-lst-c69="" src={Cricketjpg} alt="" />
        </div>
        <div _ngcontent-lst-c69="" id="gameHighlightWrap" style={{ marginBottom: '0' }} class="highlight-edge-content col3 ">
          <h3 _ngcontent-lst-c69="" style={{ textAlign: 'left' }}>Sports Highlights
          <div class="highlight-sorting">
						<label for="lang">View by</label>
						<div class="select">
							<select id="viewType" name="View" onchange="HighlightsHandler.viewType();">
								<option id="competitionName" value="competitionName" orderby="asc" priority="asc">Competition</option>
								<option id="openDateTime" value="openDateTime" orderby="asc" inplay="desc" selected="">Time</option>
								<option id="totalMatched" value="totalMatched" orderby="desc">Matched</option>
							</select>
						</div>
					</div> </h3>
          <ul _ngcontent-lst-c69="" class="sports-header">
          <li _ngcontent-lst-c69="" class="col-game" style={{ width: 'calc(64% - 36px)' }}></li>
          <li _ngcontent-lst-c69="" class="sport-odds">1</li>
          <li _ngcontent-lst-c69="" class="col-draw">x</li>
          <li _ngcontent-lst-c69="" class="last-col">2</li>
          <li _ngcontent-lst-c69="" class="info-icon"></li>
        </ul>
        <Matches changeState={props.changeState} />

        </div>
     
      </div>
    </div>
  )
}
